import { useImage } from '#imports'

// use @nuxt/image to generate optimized thumbnail from image
export function getOptimizedImage(imageSrc: string) {
  if (!imageSrc || imageSrc === '') return ''
  const optimizedImg = useImage() // composable given by @nuxt/image
  return optimizedImg(imageSrc, undefined, { preset: 'small' })
}

// return youtube video image with medium quality
export function getYtVideoImageMQ(url: string) {
  return url.replace('default.jpg', 'mqdefault.jpg')
}

// return youtube channel image with medium quality
export function getYtChannelImageMQ(url: string) {
  return url.replace('s88', 's240')
}
